<template>
  <div class="list-view product-checkout mt-0">
    <!-- Left Col -->
    <div
      v-if="address"
      class="customer-card"
    >
      <b-card
        title="Enviar a:"
      >
        <b-card-text class="font-weight-bold">{{ address.name }}, {{ address.phone }}</b-card-text>
        <b-card-text>{{ address.address }}, Número exterior: {{ address.outdoor_number }}, {{ address.interior_number ? 'Número interior: ' + address.interior_number : '' }}, {{ address.references }}, {{ address.city }}, {{ address.country_id }}, {{ address.postalcode }}</b-card-text>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-button
              v-b-modal.address-selector
              variant="warning"
              block
            >
              Seleccionar otra dirección
            </b-button>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-button
              variant="primary"
              block
              @click="updateAddress"
            >
              Editar esta dirección
            </b-button>
          </b-col>
        </b-row>

      </b-card>

    </div>
    <div
      v-else
      class="customer-card"
    >
      <b-button
        v-b-modal.address-selector
        variant="primary"
        block
      >
        Añadir una dirección
      </b-button>
    </div>
    <div class="customer-card">
      <b-card title="Resumen del pedido">

        <!-- Cart Items -->
        <div
          class="scrollable-container media-list scroll-area mt-2"
          tagname="li"
        >
          <table class="with-table-100">
            <thead>
              <tr
                v-for="item in cart.items"
                :key="item.id"
              >
                <th class="tg-0pky">
                  <img
                    :src="item.product.thumbnails[0]"
                    rounded
                    width="70px"
                  />
                </th>
                <th class="tg-0pky">
                  <div class="media-heading d-inline-block  ml-3">
                    <h6 class="cart-item-title">
                      <b-link class="text-body mt-1">
                        {{ item.product.name }}
                      </b-link>
                    </h6>
                    <!-- <small class="cart-item-by">By LTH</small> -->
                    <small class="cart-item-by">Cantidad: {{ item.quantity }}</small>
                  </div>
                </th>
                <th class="tg-0lax text-right">
                  <h3 class="cart-item-price text-primary d-inline-block ml-3">
                    ${{ item.product.price }}
                  </h3>
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div class="checkout-options padding-0-table">
          <b-card class="padding-0-table">

            <div class="price-details">
              <h6 class="price-title">
                Detalles de compra
              </h6>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title">
                    Total
                  </div>
                  <div class="detail-amt">
                    ${{ cart.total }}
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    Precio de envío
                  </div>
                  <div class="detail-amt discount-amt text-success">
                    {{ cart.shipping ? '$' + cart.shipping : 'Gratis' }}
                  </div>
                </li>
              </ul>
              <hr>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title detail-total">
                    Total
                  </div>
                  <div class="detail-amt font-weight-bolder">
                    {{ formatter.format(parseFloat(Number.isInteger(cart.shipping) ? cart.shipping : cart.shipping.replace(/,/, '')) + parseFloat(cart.total.replace(/,/, ''))) }}
                  </div>
                </li>
              </ul>
              <b-button
                variant="success"
                block
                :disabled="!address"
                @click="$emit('next-step')"
              >
                Siguiente
              </b-button>
            </div>

          </b-card>
        </div>
      </b-card>

    </div>
    <b-modal
      id="address-selector"
      ref="address-selector"
      title="Selecciona una dirección"
      hide-footer
      size="lg"
    >
      <address-selector-modal @change-address="selectAddress" />
    </b-modal>
    <b-modal
      v-if="address"
      id="address-edit"
      ref="address-edit"
      title="Selecciona una dirección"
      hide-footer
      size="lg"
    >
      <address-selector-modal
        :address-information="address"
        :is-edit-address="true"
        :address-update-id="address.id"
        @change-address="selectAddress"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BButton, VBModal,
} from 'bootstrap-vue'
import store from '@/store'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import AddressSelectorModal from '@/views/e-commerce/e-commerce-checkout/components/AddressSelectorModal.vue'

export default {
  components: {
    // BSV
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    AddressSelectorModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    addressDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedAddress: null,
    }
  },
  computed: {
    addresses() {
      return store.state.address.addresses
    },
    address() {
      if (!this.selectedAddress) {
        const addressSelected = this.addresses.find(address => address.is_default)
        store.commit('address/setAddress', addressSelected)
        return addressSelected
      }
      const addressSelected = this.addresses.find(address => address.id === this.selectedAddress)
      store.commit('address/setAddress', addressSelected)
      return addressSelected
    },
    cart() {
      return store.state.products.cart
    },
    formatter() {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      })
    },
  },
  setup(_, { emit }) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    const onSubmit = () => {
      emit('next-step')
    }

    return {

      // Form
      onSubmit,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // FOrm Validators
      required,
      integer,
    }
  },
  mounted() {
    store.dispatch('address/index')
  },
  methods: {
    selectAddress(id) {
      this.selectedAddress = id
      this.$refs['address-selector'].hide()
      this.$refs['address-edit'].hide()
    },
    updateAddress() {
      this.$refs['address-edit'].show()
    },
  },
}
</script>

<style lang="scss">
.padding-0-table{
  margin: 0!important;
  .card-body{
    padding: 0!important;
  }
}
.with-table-100{
  width: 100%;
}
@import '@core/scss/vue/libs/vue-select.scss';
.clum-grid-2{
  grid-template-columns: 1fr 1fr!important;
  max-width: 1400px;
  margin: 0 auto;
}
@media (min-width: 991px) {
  .max-h-form{
    max-height: 480px;
  }
}
</style>
