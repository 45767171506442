<template>
  <div class="list-view product-checkout">

    <!-- Left Card -->
    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Opciones de pago</b-card-title>
        <b-card-text class="text-muted mt-25">
          Asegúrate de dar click en en método correcto
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <!-- Radios -->
        <b-form-group>
          <hr class="mb-2 mt-1">
          <b-alert
            v-if="restrictedPaymentMethods"
            variant="warning"
            show
            class="d-inline-block"
          >
            <div class="alert-body">
              <span>Uno de los productos seleccionados tiene restringidos los métodos de pago</span>
            </div>
          </b-alert>
          <b-form-radio
            v-if="availablePaymentMethods.find(paymentMethod => paymentMethod.id === 1)"
            v-model="paymentMethod"
            name="payment-method"
            value="1"
          >
            Tarjeta / Crédito / Débito / Oxxo a través de Conekta
          </b-form-radio>
          <b-form-radio
            v-if="availablePaymentMethods.find(paymentMethod => paymentMethod.id === 2)"
            v-model="paymentMethod"
            name="payment-method"
            class="mt-1"
            value="2"
          >
            Paga usando puntos
          </b-form-radio>
          <b-form-radio
            v-if="availablePaymentMethods.find(paymentMethod => paymentMethod.id === 4)"
            v-model="paymentMethod"
            name="payment-method"
            class="mt-1"
            value="4"
          >
            Paga mediante SPEI
          </b-form-radio>
          <b-form-radio
            v-if="availablePaymentMethods.find(paymentMethod => paymentMethod.id === 3)"
            v-model="paymentMethod"
            name="payment-method"
            class="mt-1"
            value="3"
          >
            Paga mediante el centro de costos (método de pago solo disponible para personal staff de Clarios)
          </b-form-radio>
        </b-form-group>
        <b-row
          ref="formPoints"
          class="mt-1 hidden"
        >
          <b-col
            md="4"
            class="mb-1"
          >
            <b-form-checkbox
              id="usingPoints"
              v-model="usingPoints"
              name="using_points"
              value="accepted"
              unchecked-value="not_accepted"
            >
              ¿Deseas también usar puntos?
            </b-form-checkbox>
            <b-form-group
              ref="pointsGroup"
              label="Ingresa la cantidad de puntos a usar:"
              label-for="points_used"
              class="hidden"
            >
              <b-input-group>
                <b-form-input
                  id="points_used"
                  v-model="pointsUsed"
                  name="points_used"
                  placeholder="Cantidad de puntos a usar"
                  type="number"
                  min="0"
                  step=".01"
                  :max="getCartSubtotal + getShippingCost"
                />
                <b-input-group-append>
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="setMaxPoints"
                  >
                    Usar todos
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Resumen del pedido">

        <!-- Cart Items -->
        <div
          class="scrollable-container media-list scroll-area mt-2"
          tagname="li"
        >
          <table class="with-table-100">
            <thead>
              <tr
                v-for="item in cart.items"
                :key="item.id"
              >
                <th class="tg-0pky">
                  <img
                    :src="item.product.thumbnails[0]"
                    rounded
                    width="70px"
                  >
                </th>
                <th class="tg-0pky">
                  <div class="media-heading d-inline-block  ml-3">
                    <h6 class="cart-item-title">
                      <b-link class="text-body mt-1">
                        {{ item.product.name }}
                      </b-link>
                    </h6>
                    <!-- <small class="cart-item-by">By LTH</small> -->
                    <small class="cart-item-by">Cantidad: {{ item.quantity }}</small>
                  </div>
                </th>
                <th class="tg-0lax text-right">
                  <h3 class="cart-item-price text-primary d-inline-block ml-3">
                    ${{ item.product.price }}
                  </h3>
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <div class="checkout-options padding-0-table">
          <b-card class="padding-0-table">

            <div class="price-details">
              <h6 class="price-title">
                Detalles de compra
              </h6>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title">
                    Total
                  </div>
                  <div class="detail-amt">
                    ${{ cart.total }}
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    Precio de envío
                  </div>
                  <div class="detail-amt discount-amt text-success">
                    {{ cart.shipping ? '$' + cart.shipping : 'Gratis' }}
                  </div>
                </li>
                <li
                  v-if="paymentMethod === '1' || paymentMethod === '4'"
                  class="price-detail"
                >
                  <div class="detail-title">
                    IVA
                  </div>
                  <div class="detail-amt discount-amt text-success">
                    {{ formatter.format(getIvaCost) }}
                  </div>
                </li>
                <li
                  v-if="usingPoints === 'accepted' && pointsUsed > 0"
                  class="price-detail"
                >
                  <div class="detail-title">
                    Puntos usados
                  </div>
                  <div class="detail-amt discount-amt text-danger">
                    -{{ formatter.format(pointsUsed) }}
                  </div>
                </li>
              </ul>
              <hr>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title detail-total">
                    Total
                  </div>
                  <div class="detail-amt font-weight-bolder">
                    {{ formatter.format(getCartTotal) }}
                  </div>
                </li>
              </ul>
              <b-button
                variant="success"
                block
                :disabled="submitting"
                @click="submitBuy"
              >
                <b-spinner
                  v-if="submitting"
                  small
                />
                Pagar
              </b-button>
            </div>

          </b-card>
        </div>
      </b-card>
    </div>

    <b-modal
      id="conekta"
      ref="redirectConektaModal"
      title="La orden se ha creado con éxito"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      ok-title="Pagar"
    >
      <h6>En 20 segundos serás redireccionado a la página para realizar tu pago, también puedes dar clic en el botón de abajo para ir rápidamente</h6>
      <div>
        <div class="d-block text-center">
          <h6>Pasos para completar tu compra:</h6>
        </div>
        <p>1. Selecciona un método de pago</p>
        <p>2. Ingresa los datos correspondientes</p>
        <p>3. Regresarás a la plataforma automáticamente después de unos segundos y comprobaremos tu pago</p>
      </div>
      <b-button
        variant="primary"
        :href="redirectPage"
      >
        Ir a pagar
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormRadio,
  BButton,
  VBModal,
  BSpinner,
  BAlert,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BButton,
    BSpinner,
    BAlert,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentMethod: '2',
      redirectPage: '',
      submitting: false,
      pointsUsed: 0,
      pointsValidate: false,
      usingPoints: '',
      availablePaymentMethods: [],
    }
  },
  computed: {
    address() {
      return store.state.address.address
    },
    cart() {
      return store.state.products.cart
    },
    formatter() {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      })
    },
    restrictedPaymentMethods() {
      if (!Object.keys(this.cart).length && !Object.hasOwn(this.cart, 'items')) {
        return false
      }
      for (let i = 0; i < this.cart.items.length; i += 1) {
        if (this.cart.items[i].product.payment_methods.length) {
          return true
        }
      }
      return false
    },
    getCartSubtotal() {
      return parseFloat(Number.isInteger(this.cart.total) ? this.cart.total : this.cart.total.replace(/,/, ''))
    },
    getShippingCost() {
      if (!this.cart) {
        return 0
      }
      return parseFloat(Number.isInteger(this.cart.shipping) ? this.cart.shipping : this.cart.shipping.replace(/,/, ''))
    },
    getIvaCost() {
      if (!this.cart) {
        return 0
      }
      if (this.usingPoints === 'accepted' && this.pointsUsed > 0) {
        return (this.getCartSubtotal + this.getShippingCost - this.pointsUsed) * 0.16
      }
      return (this.getCartSubtotal + this.getShippingCost) * 0.16
    },
    getCartTotal() {
      if (!this.cart) {
        return 0
      }
      if (this.paymentMethod === '1' || this.paymentMethod === '4') {
        if (this.usingPoints === 'accepted' && this.pointsUsed > 0) {
          return this.getCartSubtotal + this.getShippingCost + this.getIvaCost - this.pointsUsed
        }
        return this.getCartSubtotal + this.getShippingCost + this.getIvaCost
      }
      return this.getCartSubtotal + this.getShippingCost
    },
  },
  watch: {
    pointsUsed(newValue) {
      if (newValue > (this.getCartSubtotal + this.getShippingCost)) {
        this.pointsUsed = this.getCartSubtotal + this.getShippingCost
      }
    },
    paymentMethod(newValue) {
      if (parseInt(newValue, 0) === 4) {
        this.$refs.formPoints.classList.remove('hidden')
      } else {
        this.$refs.formPoints.classList.add('hidden')
      }
    },
    usingPoints(newValue) {
      if (newValue === 'accepted') {
        this.$refs.pointsGroup.$el.classList.remove('hidden')
      } else {
        this.$refs.pointsGroup.$el.classList.add('hidden')
      }
    },
  },
  created() {
    console.log('pasa')
    this.$store.dispatch('products/indexPaymentMethods').then(
      paymentMethods => {
        console.log(paymentMethods)
        this.availablePaymentMethods = paymentMethods.data
      },
      error => console.log(error),
    )
  },
  methods: {
    handleSubmit() {
      this.$refs.submitBtn.click()
    },
    setMaxPoints() {
      this.pointsUsed = this.$store.state.account.user.points
    },
    isAvailablePaymentMethod(id) {
      return this.availablePaymentMethods.find(paymentMethod => paymentMethod.id === id)
    },
    submitBuy() {
      this.submitting = true
      const params = {
        address_id: this.address.id, paymentMethod: this.paymentMethod,
      }
      if (this.usingPoints === 'accepted') {
        params.points_used = parseFloat(this.pointsUsed)
      }
      store.dispatch('products/buy', { params }).then(
        response => {
          this.submitting = false
          console.log(response)
          if (!response.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'AlertCircleIcon',
                text: response.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-center',
              timeout: 10000,
            })
            return Promise.resolve()
          }
          if (!response.data.redirectUrl) {
            return router.push({ name: 'payment-success' })
          }
          this.redirectPage = response.data.redirectUrl
          this.$refs.redirectConektaModal.show()
          setTimeout(() => {
            window.location.href = response.data.redirectUrl
          }, 20000)
          return Promise.resolve()
        },
        error => {
          this.submitting = false
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificación',
              icon: 'AlertCircleIcon',
              text: error.data.message,
              variant: 'danger',
            },
          },
          {
            position: 'bottom-center',
            timeout: 10000,
          })
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}

::v-deep b-form-radio-group label, ::v-deep .custom-checkbox label {
  color: #5e5873!important;
}
</style>
