<template>

  <div class="checkout-items">
    <b-alert
      v-if="anyProductOutOfStock"
      v-height-fade.appear
      variant="warning"
      show
      class="mb-0 d-inline-block mb-2"
    >
      <div class="alert-body">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        Los productos marcados sin inventario serán envidos posteriormente.
      </div>
    </b-alert>

    <b-card
      v-for="(product, index) in products"
      :key="product.product_id"
      class="ecommerce-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img">
        <b-link>
          <b-img
            :src="product.product.thumbnails[0]"
            :alt="`${product.product.name}-${product.product.id}`"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ product.product.name }}
            </b-link>
          </h6>
          <!-- <span class="item-company">Marca <b-link class="company-name">{{ product.brand }}</b-link></span>-->
        </div>
        <span
          v-if="product.product.stock > 0"
          class="text-success mb-1"
        >En inventario</span>
        <span
          v-else
          class="text-danger mb-1"
        >No disponible</span>
        <div class="item-quantity">
          <span class="quantity-title">Cantidad:</span>
          <b-form-spinbutton
            v-model="product.quantity"
            size="sm"
            class="ml-75"
            inline
            @change="updateProduct(product, index)"
          />
        </div>
        <div
          v-for="variant in product.variants_labels"
          :key="variant"
          class="d-block"
        >
          <div
            v-if="variant"
          >
            <strong class="quantity-title">{{ getVariant(variant.key) }}: </strong>
            <span class="quantity-title">{{ variant.value }}</span>
          </div>
        </div>
        <b-alert
          variant="secondary"
          show
          class="d-inline-block mt-1"
        >
          <div class="alert-body">
            <span>Compra mínima de <strong>{{ product.product.lower_qty_limit }}</strong> paquetes.</span>
          </div>
        </b-alert>
        <div v-if="product.product.free_shipping_enabled">
          <span
            v-if="product.quantity >= product.product.qty_free_shipping"
            class="text-success"
          >Envío gratis</span>
          <span
            v-else
            class="text-warning"
          >Envío gratis al comprar más de {{ product.product.qty_free_shipping }} paquetes</span>
        </div>

      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              ${{ product.product.price }}
            </h4>
            <p
              v-if="product.product.free_shipping_enabled && product.quantity >= product.product.qty_free_shipping"
              class="card-text shipping"
            >
              <b-badge
                pill
                variant="light-success"
              >
                Envío gratis
              </b-badge>
            </p>
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProduct(product.id)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Eliminar</span>
        </b-button>
      </div>
    </b-card>

  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton, BAlert,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import store from '@/store'
import { VARIANT_LABELS } from '@/config/app'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    products: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    anyProductOutOfStock() {
      if (!this.products) {
        return false
      }
      let result = false
      console.log(this.products)
      this.products.forEach(item => {
        if (item.product.stock <= 0) {
          result = true
        }
      })
      return result
    },
  },
  setup() {
    return {
      // Filter
      formatDate,
    }
  },
  methods: {
    removeProduct(productId) {
      return store.dispatch('products/removeProductFromCart', { item_id: productId })
    },
    // eslint-disable-next-line no-unused-vars
    updateProduct(product, index) {
      return store.dispatch('products/updateProductFromCart', { item_id: product.id, quantity: product.quantity }).then(
        response => {
          this.products[index].quantity = response.data.items[index].quantity
          return Promise.resolve(response)
        },
        error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Aviso',
              icon: 'AlertCircleIcon',
              text: error.data.message,
              variant: 'danger',
            },
          }, {
            position: 'bottom-center',
            timeout: 10000,
          })
          this.products[index].quantity -= 1
          return Promise.reject(error)
        },
      )
    },
    getVariant(variant) {
      return VARIANT_LABELS[variant]
    },
  },
}
</script>

<style>

</style>
